import * as React from "react"

import Layout from "../components/layout";
import OurProcesssComponent from "../components/ourProcesss";

const OurProcess = () => (
    <Layout title="our-process">
        <OurProcesssComponent />
    </Layout>
)

export default OurProcess