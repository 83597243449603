import React, { useState, useEffect } from "react"
import ScrollReveal from 'scrollreveal'
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import Help from "./help"
import PromiseSection from "./promiseSection"
import { YoutubeModal } from "../components/common/youTubeModal"
import Services from "./common/services"
import ButtonField from "./common/form/ButtonField"
import { ANIMATION_DURATION } from "../constant"

const OurProcess = () => {
  const [modalShow, setModalShow] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")
  const data = useStaticQuery(graphql`
    query {
      processData: allStrapiProcesses {
        nodes {
          Description
          Title
          backgroundImage {
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 0, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          introText
          step {
            title
            step {
              title
              description
              image {
                alternativeText
                localFile {
                  childImageSharp {
                    fluid(quality: 10, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          Help {
            Title
            help_list {
              title
              description
            }
            help_question {
              title
              description
              image {
                alternativeText
                localFile {
                  childImageSharp {
                    fluid(quality: 10, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          repair_table {
            title
            TH1
            TH2
            repair_question {
              title
              description
              image {
                alternativeText
                localFile {
                  childImageSharp {
                    fluid(quality: 10, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            table_data {
              title
              description
            }
          }
          aboutTarakEnabled
          ourMissionEnabled
          repairCostEnabled
          stepsEnabled
          testimonialEnabled
          helpEnabled
          animationEffect
        }
      }
      allStrapiFeedBack {
        nodes {
          FeedbackText
          Title
          clientNames
          strapiId
        }
      }
      googleReview: file(relativePath: { eq: "locationReview.png" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { Description, Title, backgroundImage, step, introText, repair_table, stepsEnabled = true, aboutTarakEnabled = true, ourMissionEnabled = true, testimonialEnabled = true, helpEnabled, repairCostEnabled ,animationEffect=false} = data.processData.nodes[0]
  const googleReview = data.googleReview.childImageSharp.fluid
  const FeedBackData = data.allStrapiFeedBack.nodes

  useEffect(() => {
    if(animationEffect){
      ScrollReveal().reveal('.feedBackPosts ', { delay: ANIMATION_DURATION });
    }
  }, [animationEffect])
  const onHelpScroll = () => {
    var my_element = document.getElementById("offsetHelp")
    window.scroll({
      top: my_element.offsetTop - 70,
      behavior: "smooth",
    })
  }
  return (
    <div className="ourProcessContainer">
      <BackgroundImage className="homeImg ml-0 mb-0" fluid={backgroundImage.localFile.childImageSharp.fluid} alt={backgroundImage?.alternativeText}>
        <div className="d-flex text-white flex-column pt-3 align-items-center introContentDiv">
          <div className="intro-content px-1">
            <h1>
              {Title}
            </h1>
            <div className="text-center topDescription mx-3">
              {Description}
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="video-content">
        <iframe
          title="iframe"
          className="videoRadio"
          srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img{position:absolute;width:100%;top:0;bottom:0;margin:auto}</style><a ><img src=https://img.youtube.com/vi/LvqZ07fPFtY/hqdefault.jpg </a>"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <span
          role="none"
          onKeyDown={() => ""}
          onClick={() => {
            setVideoUrl("https://www.youtube.com/embed/LvqZ07fPFtY")
            setModalShow(true)
          }}
          className="playBotton cursor-pointer"
        >
          ▶
        </span>
      </div>
      {stepsEnabled && <>
        <Services data={step[0].step} description={true} sellContent={true} animationEffect={animationEffect} />
        <div className="text-center buttonPadding">
          <ButtonField onClick={() => onHelpScroll()} className="LearnMoreBtn" name="Learn More" />
        </div>
      </>}
      <PromiseSection aboutTarakEnabled={aboutTarakEnabled} introText={introText} animationEffect={animationEffect} />
      <div>
        {ourMissionEnabled && <div className="max2000">
          <Services data={step[1].step} ourMissionSection={true} description={true} title={step[1].title} className="pb-5" animationEffect = {animationEffect} />
        </div>
        }
        {testimonialEnabled && <div className="testimonialsArea blogContainer">
          <div className="testimonials-bubble">
            <h2>
              See what sellers say about Tarek Buys Houses!...
            </h2>
          </div>
          <div style={{ background: "white" }}>
            <div className="max2000">
              <div className="feedBackPosts d-flex justify-content-center row row-cols-3">
                {FeedBackData.map((val, i) => {
                  return (
                    <div key={i} className="feedBackBox d-flex flex-column col">
                      <div className="feedBackTitle text-center">
                        "{val.Title}"
                      </div>
                      <hr className="solid feedBackUnderline" />
                      <div
                        className="mb-2 feedBackSubTitle text-center"
                        style={{
                          margin: "auto",
                        }}
                      >
                        {val.FeedbackText}
                      </div>
                      <div className="mt-2 mb-2 text-center feedBackSeller">
                        {val.clientNames}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="google-business-reviews pt-5">
                <Img
                  fluid={googleReview}
                  style={{ height: 47, width: 200, margin: "auto" }}
                  alt="google-business-reviews"
                />
              </div>
            </div>
          </div>
        </div>}
      </div>
      <Help data={data.processData.nodes[0].Help[0]} locationHelpQuestion={data.processData.nodes[0].Help[0].help_question} repairData={repair_table} helpEnabled={helpEnabled} repairCostEnabled={repairCostEnabled} animationEffect={animationEffect} />
      <YoutubeModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        videoUrl={videoUrl}
        className="ourProcessModel"
      />
    </div>
  )
}
export default OurProcess
